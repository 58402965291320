import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PortfolioIndex from "../components/portfolioIndex"
import SEO from "../components/seo"
import ContentBlock from "../components/contentBlock"
import Breadcrumbs from "../components/breadcrumbs"
import BreadcrumbsBar from "../components/breadcrumbsBar"
import Title from "../components/title"

class Portfolio extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Portfolio - Desarrollo de Aplicaciones, Diseño Web, Ecommerce, Marketing Digital" />
        <ContentBlock theme="white">
          <BreadcrumbsBar>
            <Breadcrumbs name="FeedYourWeb" page="/" color="#666" />
            <Breadcrumbs name="Portfolio" page="/portfolio" color="#666" />
          </BreadcrumbsBar>
          <Title className="dark-gray">
            Portfolio
            <br />
            Aplicaciones <br />
            Diseño Web <br />
            Ecommerce <br />y Marketing Digital
          </Title>
          <PortfolioIndex theme="light" />
        </ContentBlock>
      </Layout>
    )
  }
}

export default Portfolio

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
