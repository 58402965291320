import React from "react"

import { useStaticQuery, Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"

import Styled from "styled-components"

import TagBar from "../components/tagBar"

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`
const Item = Styled.div`
  padding: ${rhythm(1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Subtitle = Styled.h2`
  color: ${props => props.theme};
  margin: ${rhythm(1 / 4)};
  box-shadow: none;
  text-align: center;
  font-size: ${rhythm(0.7)};
`

const ProjectLink = Styled(Link)`
  text-decoration: none;
  box-shadow: none;
`

const PortfolioIndex = props => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { tags: { regex: "/PORTFOLIO/" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
              }
            }
          }
        }
      }
    `
  )
  const posts = data.allMarkdownRemark.edges
  // console.log("portfolioIndex posts:", posts)

  const theme = (props.theme && props.theme === 'light') ? colors.midDarkGray : '#000'

  let count = 0

  return (
    <Container>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        let json = { type: "", star: false, src: false }
        if (node.frontmatter.description) {
          try {
            json = JSON.parse(node.frontmatter.description)
          } catch (e) {
            console.error("error: ", e)
          }
        }

        // Starred only?
        if (props.starred && !json.star) {
          return (<></>)
        }

        // Limit reached?
        if (count >= props.limit) {
          return (<></>)
        }

        count++

        return (
          <Item key={title}>
            <ProjectLink to={node.fields.slug}>
              <div className={ 'portfolio-image ' + json.type}>
                <img src={json.src} alt={title} />
              </div>
              <Subtitle theme={theme}>{title}</Subtitle>
            </ProjectLink>
            <TagBar tags={node.frontmatter.tags} />
          </Item>
        )
      })}
    </Container>
  )
}

export default PortfolioIndex
