import React from "react"

import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"
import Styled from "styled-components"

const Container = Styled(Link)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${rhythm(0.2)};
  color: ${props => props.color || colors.midGray};
  white-space: normal;
  text-align: center;
  max-width: ${rhythm(6)};
  font-size: ${rhythm(0.45)};
  transition: all 0.2s ease-in;

  &:hover {
    font-weight: bold;
  }
`
const ContainerNoLink= Styled.span`
  margin: ${rhythm(0.2)};
  color: ${props => props.color || colors.midGray};
  white-space: normal;
  text-align: center;
  max-width: ${rhythm(15)};
  font-size: ${rhythm(0.45)};
`
const Separator = Styled.span`
  margin: ${rhythm(0.01)};
  color: ${props => props.color};
  font-size: ${rhythm(0.6)};
  padding-top: ${rhythm(0.2)};
`

const separator = page => {
  if (page === '/') {
    return
  }
  return (<Separator color={colors.green}>*</Separator>)
}

const Breadcrumbs = ({name, page, color}) => {

  if (!page) {
    return (
      <>
        {separator(page)}
        <ContainerNoLink color={color}>{name}</ContainerNoLink>
      </>
    )
  }

  return (
    <>
      {separator(page, color)}
      <Container color={color} to={page}>{name}</Container>
    </>
  )
}

export default Breadcrumbs
