import React from "react"

import Styled from "styled-components"

import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"


const Wrapper = Styled.section`
  padding: ${rhythm(1 / 2)} ${rhythm(1)};
  max-width: 100%
`
const Tag = Styled.div`
  display: inline-block;
  margin: ${rhythm(1 / 8)};
  h2 {
    display: inline;
    padding: ${rhythm(1 / 8)} ${rhythm(1 / 4)};
    font-size: ${rhythm(1 / 2)};
    font-weight: 400;
    font-family: Helvetica Neue;
    color: ${colors.midGray};
    background: #eee;
    border-radius: 5px;
    box-shadow: none;
  }
  h3 {
    display: inline;
    padding: ${rhythm(1 / 6)} ${rhythm(1 / 6)};
    font-size: ${rhythm(1 / 2)};
    font-weight: 400;
    font-family: Helvetica Neue;
    color: ${colors.darkGray};
    background: #eee;
    border-radius: 5px;
    box-shadow: none;
  }
  a, span {
    word-break: keep-all;
    color: inherit;
    text-decoration: none;
    box-shadow: none;
  }
`

const TagBar = ({ tags, small }) => {

  const tagsArr = tags.split(",")
  if (!tagsArr.length) {
    return
  }

  let content = tag => (
    <h2>
      {/* <a href={`/tag/${tag}`}>{tag}</a> */}
      {/* <a>{tag}</a> */}
      <span>{tag}</span>
    </h2>
  )
  if (small) {
    content = tag => (
      <h3>
        {/* <a href={`/tag/${tag}`}>{tag}</a> */}
        {/* <a>{tag}</a> */}
        <span>{tag}</span>
      </h3>
    )
  }

  return (
    <Wrapper>
      {tagsArr.map(tag => (
        <Tag key={tag.replace(' ', '')}>{content(tag.trim())}</Tag>
      ))}
    </Wrapper>
  )
}

export default TagBar
