import React from "react"

import Styled from "styled-components"

import { rhythm } from "../utils/typography"
import { device } from "../utils/device"

const Container = Styled.h1`
  font-size: ${rhythm(0.5)};
  line-height: ${rhythm(0.75)};
  font-family: ${props => props.font};
  font-weight: 900;
  text-rendering: optimizeLegibility;
  text-align: center;
  color: ${props => props.color};
  margin: ${rhythm(0.8)} auto;

  @media ${device.mobileL} {
    font-size: ${rhythm(0.7)};
    line-height: ${rhythm(1)};
  }
  @media ${device.tablet} {
    font-size: ${rhythm(1)};
    line-height: ${rhythm(1.3)};
  }
  @media ${device.laptop} {
    font-size: ${rhythm(1)};
    line-height: ${rhythm(1.3)};
  }
  @media ${device.desktopL} {
    font-size: ${rhythm(1.3)};
    line-height: ${rhythm(1.6)};
  }
`

const Title = ({ children, font, color }) => {
  font = font  === 'lily' ? `LilyScriptOne-Regular` : `Montserrat,sans-serif`
  return <Container font={font} color={color}>{children}</Container>
}

export default Title
