import React from "react"

import { rhythm } from "../utils/typography"
import Styled from "styled-components"

const Container = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: ${rhythm(0.3)};
`

const BreadcrumbsBar = props => {
  return (<Container>{props.children}</Container>)
}

export default BreadcrumbsBar
